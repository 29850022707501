body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
  /* padding-bottom: 5.25em; height of your footer */
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
span.kid {
  color: var(--primary-color);
}
span.skope {
  color: var(--secondary-color);
}
h4 {
  font-size: 1.25rem;
}
.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: none;
}
.requiredfield label::after {
  content: " *";
  color: red;
}
/* #1ec0e0; */
:root {
  --primary-color: #00b4f0;
  --secondary-color: #05ab99;
  --grey-color: #eaeaea;
  --font-color: #284667;
  --grey-dark: #cfc8c8;
  --font-familty: Calibri;
  --circle-gradient: linear-gradient(
    45deg,
    rgb(0, 153, 183),
    rgba(78, 220, 85, 0.65)
  );
}
a {
  color: var(--primary-color);
}
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  margin-top: 100px;
  /* margin-bottom: 20px; */
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color);
}
.container {
  margin-top: 3.5em;
  margin-bottom: 2rem;
}

#myapproot {
  height: 0;
}

.cropcontainer img {
  height: auto !important;
}
.title_text {
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 1px 1px beige;
}
.subtitle_text {
  font-size: 1.5rem;
  margin-bottom: 0.75em;
}
.text_inline {
  display: inline-block;
}

.circledtext {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  background-image: var(--circle-gradient);
  /* font-size: 1rem; */
  text-align: center;
  margin: 2em 2em 0.5em 2em;
  font-weight: bold;
}

.jumbotron {
  padding: 0 !important;
  margin: 0 !important;
}
.hidescroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidescroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.listingcards {
  /* margin: 1em; */
  margin-top: 1em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1.5em;
  /* height: 100vh; */
}

.listingcards a,
.listingcards a:hover {
  text-decoration: none !important;
  color: #294667;
}

.homesearchbtn.btn-primary {
  padding: 0.5em;
  font-size: 1.2rem !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 4px 10px;
  /* padding: 1em 2em !important; */
}
.homesearchbtn.btn-primary:hover {
  background-color: var(--secondary-color) !important;
}
.homesearchbtn.btn-primary:hover span {
  display: none;
}
.homesearchbtn.btn-primary:hover:before {
  content: "\2004\2004\2004\2004\2004\2004\2004Let's Go!\2004\2004\2004\2004\2004\2004\2004";
}
.form-check-label {
  padding-top: 0.25em !important;
}
.percentbar {
  background-color: #80808026;
  border-radius: 13px;
  border: 1px solid #80808026;
}

.percentbar > div {
  background-color: rgba(170, 219, 223, 0.575);
  /* Adjust with JavaScript */
  height: 20px;
  border-radius: 10px;
  transition: width 2s !important;
  display: flex;
  align-items: center;
}
.steppercentbar {
  background-color: #80808026;
  border-radius: 8px;
  border: 1px solid #80808026;
  font-size: 0.5rem;
}

.steppercentbar > div {
  background-color: rgba(170, 219, 223, 0.575);
  /* Adjust with JavaScript */
  height: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.field-container {
  align-items: center;
  width: 100%;
}
.vigresscontainer {
  min-height: calc(100vh - 120px);
  /* background-color: rgba(14, 13, 13, 0.575); */
  display: flex;
}
.toolbarcontainer button {
  /* padding-left: 2em !important; */
  height: unset !important;
}
.toolbarcontainer .MuiButton-root {
  line-height: unset !important;
}
.MuiTableCell-root {
  /* padding: 0 !important; */
  padding: 0.25rem !important;
  border-bottom: unset !important;
  /* padding: 4px 4px !important; */
}
.MuiTableCell-head {
  padding: 8px 8px 8px 0 !important;
}
.MuiInputBase-input.Mui-disabled {
  background-color: #eaeaea !important;
}
.headerbar {
  background-color: #eaeaea;
  font-weight: bold;
  margin-bottom: 0.5em;
  border-radius: 0.5em;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.tabsbar {
  background-color: #eaeaea;
  margin-bottom: 0.5em;
  border-radius: 0.5em;
}
.primary-color {
  color: var(--primary-color);
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.MuiTableHead-root {
  background-color: #eaeaea;
}
.MuiTableCell-stickyHeader {
  background-color: #eaeaea !important;
}
.filterscontainer .MuiOutlinedInput-root {
  font-size: 0.75rem !important;
  min-width: 135px !important;
  /* max-width: 135px !important; */
}
.filterscontainer .MuiButton-containedSizeSmall {
  padding: 6px !important;
  height: fit-content !important;
}

.custom-file-upload input[type="file"] {
  display: none;
}
.statusdatescontainer button {
  padding: 0 !important;
  width: 100%;
}
.statusdatescontainer {
  font-size: 0.8125rem !important;
}
button a {
  text-decoration: none !important;
}
.custom-file-upload {
  display: block;
  cursor: pointer;
  border: 1px solid rgba(0, 180, 240, 0.5);
  color: var(--primary-color);
  font-size: 0.875rem;
  min-width: 100%;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: 14px;
  padding-left: 14px;
}
.MuiOutlinedInput-input {
  padding: 10.5px 14px !important;
}
.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #0e0e0e0f !important;
}
.MuiFormControl-root {
  width: 100% !important;
}
.MuiButton-root,
.MuiTab-root {
  text-transform: unset !important;
  font-weight: normal !important;
}
.vigresstabs .MuiTabs-flexContainer {
  height: 35px !important;
  align-items: center;
  /* overflow: auto; */
}
.MuiTabs-root {
  min-height: 35px !important;
}
.MuiTableHead-root th:first-child {
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}
.MuiTableHead-root th:last-child {
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}
.MuiTableBody-root td:first-child {
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}
.MuiTableBody-root th:first-child {
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}
.MuiTableBody-root td:last-child {
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}
.editableTable .MuiTableCell-root {
  vertical-align: top !important;
}
.editableTable tbody tr:first-child .MuiTableCell-root {
  padding-top: 0.5rem !important;
}
.MuiTab-textColorPrimary {
  color: var(--primary-color) !important;
}
.MuiTab-textColorPrimary.Mui-selected {
  color: #000000de !important;
}
.MuiTab-root {
  font-weight: normal !important;
}
.dateCompleted .MuiOutlinedInput-adornedEnd {
  padding-right: 0 !important;
}
.MuiMenu-paper {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  min-height: 100px !important;
  min-width: 100px !important;
  border-radius: 0.5em !important;
}
.Mui-disabled fieldset {
  background-color: #eaeaea;
}

.Mui-disabled p,
input {
  z-index: 1;
}
.reportscontainer .MuiTable-root {
  width: 99.9% !important;
}
.collapsetable thead th {
  padding: 0 !important;
}
.reportstable tbody > tr.oddrow {
  background-color: #f8f8f8 !important;
}
.toolbarList .MuiListItem-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/* Esri style */
.esri-ui-corner .esri-component {
  border-radius: 6px !important;
}
.esri-zoom .esri-widget--button:first-child {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  /* box-shadow: 0.5px -1px 5px 2px #b1afafc2 !important; */
}
.esri-zoom .esri-widget--button:last-child {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  /* box-shadow: 0.5px 1px 5px 2px #b1afafc2 !important; */
}
.esri-widget--button {
  border-radius: 0.5em !important;
}
SVG G TEXT {
  pointer-events: none;
}
[class*="esri-icon"] {
  color: var(--primary-color) !important;
}
.esri-ui .esri-popup {
  border-radius: 0.5em !important;
}
.esri-popup__main-container {
  border-radius: 0.5em !important;
}
.esri-ui-bottom-right {
  flex-flow: column-reverse !important;
}
.esri-ui-bottom-right .esri-component {
  margin-bottom: 5px;
}
@media (min-width: 900px) {
  .toolbarcontainer button {
    padding-left: 2em !important;
  }
}
