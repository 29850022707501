html,
body {
  overflow: auto !important;
  height: 100%;
}

body {
  height: 100%;
}

.btn-primary {
  background-color: #1ec0e0 !important;
  border: none !important;
}
.btn-outline-primary {
  color: #284667 !important;
  border-color: #1ec0e0 !important;
}
.btn-outline-primary:hover {
  background-color: #c7e5f7 !important;
}
.formpadding {
  padding-top: 0px;
  padding-left: 1.125em;
  padding-right: 1.125em;
  padding-bottom: 1.25em;
}
.modal-content {
  border-radius: 20px !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.modal-header {
  font-size: large !important;
  border-bottom: none !important;
}

.smalllink {
  font-size: 0.8em !important;
  padding: 0px !important;
}
.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: auto;
  margin-left: auto;
}
.formheader {
  text-align: center;
  padding-bottom: 1.25em;
  font-size: 1.25rem;
  color: #1ec0e0;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
label.form-label {
  color: #284667;
}
.formcard {
  position: relative;
  /* display: flex; */
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  /* background-color: #fff; */
  background-clip: border-box;
  /* border: 2px solid rgba(46, 36, 36, 0.125); */
  border-radius: 0.75rem;
  border: 1px solid #13b5de45;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.numberCircle {
  border-radius: 50%;
  width: 66px;
  height: 66px;
  padding: 0.5em;

  background: #fff;
  border: 2px solid #284667;
  color: #284667;
  text-align: center;

  font: 2rem Arial, sans-serif;
}

.stepper {
  counter-reset: step;
}

.stepper li {
  list-style-type: none;
  float: left;
  width: 33.33%;
  position: relative;
  text-align: center;
  color: #284667;
  margin-bottom: 10px;
}

.stepper li:before {
  content: counter(step);
  counter-increment: step;
  font-size: 2rem;
  color: #0e0d0d;
  width: 60px;
  height: 60px;
  border: 3px solid #c8dcf3;
  display: block;
  text-align: center;
  margin: 0.4em auto 0.4em auto;
  border-radius: 50%;
  background-color: #fff;
}
.stepper li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: grey;
  top: 40px;
  left: -50%;
  z-index: -1;
}
.stepper li:first-child:after {
  content: none;
}
.stepper li.active:after {
  background-color: #284667;
}
.stepper li.active:before {
  background-color: #c8dcf3;
}
.stepper li.activecomplete:before {
  content: "\2713 ";
  background-color: green;
}

.headerMenu {
  padding-top: 0.25em;
  display: none;
  overflow: hidden;
  background-color: #fff;
}
.headerMenu li {
  list-style-type: none;
}
.headerMenu a {
  color: #284667;
}
.headerMenu a:hover {
  color: #284667;
}
.react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  background-color: #294667;
}
.react-datepicker__header {
  border-bottom: 1px solid #294667;
  background-color: #dfecf4;
}
.react-datepicker {
  color: #294667;
  border: 1px solid #294667;
}
.heading {
  color: #294667;
  font-size: 2rem;
  font-weight: bold;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #294667 !important;
}

.viewlabel {
  color: #05ab99;
  line-height: 1.5;
  font-size: inherit;
}

.logincontainer {
  width: 350px;
  border: 1px solid var(--primary-color);
  border-radius: 0.5em;
}

.logininner {
  margin: 1em;
}
.logintitle {
  text-align: center;
  color: var(--primary-color);
  font-size: 1.5rem;
  margin-top: 0.25em;
}
.lmswitch .MuiSwitch-root {
  margin: 0 !important;
}
